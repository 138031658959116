<template>
  <div class="qiu">
    <div id="china_map"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import china from "@/utils/china.json";
echarts.registerMap("china", china);
export default {
  data() {
    return {};
  },

  methods: {
    //初始化中国地图
    initEchartMap(echartData = []) {
      let mapDiv = document.getElementById("china_map");
      let myChart = echarts.init(mapDiv);

      var dataValue = echartData;
      console.log(dataValue, "---------dataValue----------");
      // var data1 = dataValue.splice(0, 4);
      // dataValue = [
      //   {
      //     label:'123',
      //     value:[117.555328,31.384124]
      //   }
      // ]
      var options;
      options = {
        tooltip: {
          show: false,
          triggerOn: "mousemove", //mousemove、click
          padding: 8,
          backgroundColor: "rgba(0,0,0,0.6)",
          borderWidth: 0,
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        geo: {
          map: "china",
          roam: true, // 一定要关闭拖拽
          zoom: 1.3,
          center: [102, 36], // 调整地图位置
          aspectScale: 0.8, //长宽比
          label: {
            normal: {
              show: true, //关闭省份名展示
              fontSize: "10",
              color: "#ffffff",
            },
            emphasis: {
              show: false,
            },
          },
          silent: true, // 取消高亮  //禁用地图的hover事件
          itemStyle: {
            normal: {
              areaColor: "#0d0059",
              areaColor: "#3fa3ff",
              // borderColor: "#3890e1",
              borderWidth: 1, //设置外层边框
              shadowBlur: 6,
              shadowOffsetX: 0,
              shadowOffsetY: 8,
              shadowColor: "rgba(1, 39, 44, 1)",
            },
            // normal: {
            //     shadowColor: 'rgba(1, 39, 44, 1)',
            //     shadowOffsetX: 0,
            //     shadowOffsetY: 8
            // },
            emphasis: {
              show: false,
              // areaColor: "#184cff",
              // shadowOffsetX: 0,
              // shadowOffsetY: 0,
              shadowBlur: 5,
              borderWidth: 0,
              // shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        series: [
          {
            name: "",
            type: "scatter",
            coordinateSystem: "geo",
            data: dataValue,
            symbol: "circle",
            symbolSize: 15,
            hoverSymbolSize: 15,
            tooltip: {
              formatter(value) {
                return value.data.name;
                // return value.data.name + "<br/>" + "设备数：" + "22";
              },
              show: true,
            },
            encode: {
              value: 2,
            },
            label: {
              formatter: "{b}",
              position: "right",
              show: false,
            },
            itemStyle: {
              // color: "#e0eb40",
              color: "#fff",
              borderWidth: 3,
              borderColor: "#1be1df",
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //   },
            // },
            emphasis: {
              disabled: false,
              label: {
                //鼠标移入不显示name
                show: false,
              },
              itemStyle: {
                areaColor: "inherit",
                color: "auto",
                borderColor: "#5AB0FE",
                borderWidth: 2,
              },
            },
          },
          //下面是前五产生涟漪动画
          // {
          //   name: "Top 5",
          //   type: "effectScatter",
          //   coordinateSystem: "geo",
          //   data: data1,
          //   // symbolSize: 15,
          //   tooltip: {
          //     formatter(value) {
          //       // return value.data.name + "<br/>" + "设备数：" + "22";
          //     },
          //     show: true,
          //   },
          //   // encode: {
          //   //   value: 2,
          //   // },
          //   // showEffectOn: "render",
          //   // rippleEffect: {
          //   //   brushType: "stroke",
          //   //   color: "#e0eb40",
          //   //   period: 9,
          //   //   scale: 5,
          //   // },
          //   // hoverAnimation: true,
          //   label: {
          //     formatter: "{b}",
          //     position: "right",
          //     show: true,
          //   },
          //   itemStyle: {
          //     // color: "#e0eb40",
          //     color: "#fff",
          //     borderWidth: 2,
          //     borderColor: "#1be1df",
          //     // shadowBlur: 2,
          //     // shadowColor: "#333",
          //   },
          //   zlevel: 1,
          // },
        ],
      };
      myChart.setOption(options);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style scoped>
.qiu {
  width: 100%;
  height: 100%;
}
#china_map {
  position: absolute;
  width: 40%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
</style>
