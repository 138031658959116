<template>
    <div class="qiu">
        <div id="china_map" v-show="true" ref="Map"></div>
    </div>
</template>
<script>
import * as echarts from "echarts";
import "echarts-gl";
import "@/plugins/world"; // 引入世界地图
import WorldJson from "@/assets/json/countries.geo.json";
export default {
    data() {
        return {};
    },
    mounted() {
        this.initEchartMap([])
    },
    methods: {
        //初始化中国地图
        initEchartMap(echartData = []) {
            this.$nextTick(() => {
                let maps = this.$refs.Map;
                let mapsChart = echarts.init(maps);
                echarts.registerMap("world", WorldJson);
                let canvas = document.createElement("canvas");
                let mapChart = echarts.init(canvas, null, {
                    width: 4096,
                    height: 2048,
                });

                //航线飞机模型
                mapChart.setOption({
                    backgroundColor: '#17315b',
                    geo: {
                        name: "地图",
                        // type: 'map',  //地图种类、
                        map: "world", //地图类型。
                        zlevel: 0,
                        show: true,
                        layoutCenter: ["50%", "50%"],
                        roam: false,
                        layoutSize: "90%",
                        zoom: 1,

                        label: {
                            normal: {
                                show: false,
                                textStyle: {
                                    fontSize: 14,
                                    // 文字顔色
                                    color: "#43D0D6",
                                },
                            },
                        },
                        normal: {
                            borderWidth: 1, //设置外层边框
                            borderColor: "#1ECEF4",
                        },
                        itemStyle: {
                            //地图区域的多边形 图形样式。
                            emphasis: {
                                //高亮状态下的样试
                                label: {
                                    show: true,
                                },
                                areaColor: "#FFB800",
                            },
                            normal: {
                                areaColor: "#fff",
                                borderWidth: 1, //设置外层边框
                                borderColor: "#1ECEF4",
                            },
                        },
                    },
                    viewControl: {
                        autoRotate: false,
                    },
                    series: [
                        //这里设了2条不同效果的飞线是为了讲他们叠加起来，满足飞机后面白色的喷气动画效果
                    ],
                });

                let option = {
                    backgroundColor: '#17315b',
                    globe: {
                        globeRadius: 100,
                        globeOuterRadius: 150,
                        silent: true,
                        shading: "color",
                        environment: "#12284e99",
                        baseTexture: mapChart,
                        viewControl: {
                            autoRotate: true,
                        },
                        light: {
                            main: {
                                color: "rgba(255,255,255,0.3)",
                                intensity: 1,
                                shadow: false,
                                alpha: 55,
                                beta: 10,
                            },
                            ambient: {
                                color: "#fff",
                                intensity: 1,
                            },
                            postEffect: {
                                enable: true,
                                SSAO: {
                                    enable: true,
                                    radius: 10,
                                },
                            },
                        },
                    },
                };

                mapsChart.setOption(option);
                window.addEventListener("resize", function () {
                    mapsChart.resize();
                });
            });

        },


    },
};
</script>

<style scoped>
.qiu {
    width: 100%;
    height: 100%;
}

#china_map {
    position: absolute;
    width: 40%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0.1;
}
</style>
